<template>
  <div class="container">
    <div v-if="data.status === 0" class="invite-content">
      <div class="invite-title-content">
        <div>访客邀约</div>
        <!-- <div>中国农业银行四川省分行欢迎您!</div> -->
      </div>
      <div class="invite-main-content mb-15">
        <div class="invite-main-items">
          <div class="items-title">
            <span class="items-red-star">*</span>姓名
          </div>
          <div>
            <van-field v-model="visit.visitName" input-align="right" maxlength="10" placeholder="请输入姓名" />
          </div>
        </div>
        <CommonBorder></CommonBorder>
        <div class="invite-main-items">
          <div class="items-title">
            <span class="items-red-star">*</span>性别
          </div>
          <div>
            <van-radio-group v-model="visit.gender" direction="horizontal">
              <van-radio checked-color="#C7000B" icon-size="16px" name="1">男</van-radio>
              <van-radio checked-color="#C7000B" icon-size="16px" name="2">女</van-radio>
            </van-radio-group>
          </div>
        </div>
        <CommonBorder></CommonBorder>
        <div class="invite-main-items">
          <div class="items-title">
            <span class="items-red-star">*</span>手机号
          </div>
          <div>
            <van-field v-model="visit.contact" input-align="right" placeholder="请输入11位手机号" />
          </div>
        </div>
        <CommonBorder></CommonBorder>
        <div class="invite-main-items">
          <div class="items-title">
            <span class="items-red-star">*</span>来访单位
          </div>
          <div>
            <van-field v-model="visit.unit" input-align="right" placeholder="请输入来访单位" />
          </div>
        </div>
        <CommonBorder></CommonBorder>
        <div class="invite-main-items">
          <div class="items-title">
            <span class="items-red-star">*</span>来访事由
          </div>
          <div>
            <van-field
              v-model="reasonText"
              readonly
              input-align="right"
              placeholder="请选择来访事由"
              right-icon="arrow-down"
              @click="isReasonList=true"
            />
            <van-popup v-model="isReasonList" position="bottom">
              <van-picker
                show-toolbar
                confirm-button-text="确定"
                :columns="reasonList"
                value-key="lable"
                @confirm="reasonConfirm"
                @cancel="isReasonList=false"
              />
            </van-popup>
          </div>
        </div>
        <CommonBorder></CommonBorder>
        <div class="invite-main-items">
          <div class="items-title">
            <span class="items-red-star">*</span>身份证号
          </div>
          <div>
            <van-field v-model="visit.card" input-align="right" placeholder="请输入身份证号" />
          </div>
        </div>
        <CommonBorder></CommonBorder>
        <div class="invite-main-items">
          <div class="items-title">
            <span class="items-red-star">*</span>随行人数
          </div>
          <div>
            <van-field
              v-model="numText"
              right-icon="arrow-down"
              readonly
              input-align="right"
              placeholder="请选择随行人数"
              @click="isNumList=true"
            />
            <van-popup v-model="isNumList" position="bottom">
              <van-picker
                show-toolbar
                :columns="numList"
                confirm-button-text="确定"
                value-key="lable"
                @confirm="numConfirm"
                @cancel="isNumList=false"
              />
            </van-popup>
          </div>
        </div>
        <CommonBorder></CommonBorder>
      </div>
      <div class="invite-main-content">
        <div class="invite-main-items">
          <div class="items-title">
            <span class="items-red-star">*</span>被访部门
          </div>
          <div>
            <van-field
              v-model="departmentText"
              right-icon="arrow-down"
              readonly
              input-align="right"
              placeholder="请选择被访部门"
              @click="isDepartmentList=true"
            />
            <van-popup v-model="isDepartmentList" position="bottom">
              <van-picker
                show-toolbar
                confirm-button-text="确定"
                :columns="departmentList"
                value-key="name"
                @confirm="departmentConfirm"
                @cancel="isDepartmentList=false"
              />
            </van-popup>
          </div>
        </div>
        <CommonBorder></CommonBorder>
        <div class="invite-main-items">
          <div class="items-title">
            <span class="items-red-star">*</span>被访人
          </div>
          <div>
            <van-field v-model="visit.callName" input-align="right" maxlength="10" placeholder="请输入被访人姓名" />
          </div>
        </div>
        <CommonBorder></CommonBorder>
        <div class="invite-main-items">
          <div class="items-title">
            <span class="items-red-star">*</span>来访时间
          </div>
          <div>
            <van-field
              v-model="visit.start"
              right-icon="arrow-down"
              readonly
              input-align="right"
              placeholder="请选择来访时间"
              @click="isStart = true"
            />
            <van-popup v-model="isStart" position="bottom">
              <van-datetime-picker
                v-model="start"
                type="datetime"
                confirm-button-text="确定"
                value-format="yyyy-MM-dd hh:mm:ss"
                :min-date="minDate"
                :max-date="maxDate"
                :formatter="formatter"
                @confirm="startConfirmFn"
              />
            </van-popup>
          </div>
        </div>
        <CommonBorder></CommonBorder>
        <div class="invite-main-items">
          <div class="items-title">
            离开时间
          </div>
          <div>
            <van-field
              v-model="visit.end"
              right-icon="arrow-down"
              input-align="right"
              readonly
              placeholder="请选择离开时间"
              @click="isEnd = true"
            />
            <van-popup v-model="isEnd" position="bottom">
              <van-datetime-picker
                v-model="end"
                type="datetime"
                confirm-button-text="确定"
                value-format="yyyy-MM-dd hh:mm:ss"
                :min-date="minDate"
                :max-date="maxDate"
                :formatter="formatter"
                @confirm="endConfirmFn"
              />
            </van-popup>
          </div>
        </div>
        <CommonBorder></CommonBorder>
      </div>
      <div class="invite-submit-content">
        <van-button class="invite-submit-btn" @click="submitVisitFn">提交</van-button>
      </div>
    </div>
    <div v-else class="invite-detail-content">
      <div class="detail-content-title">访客通行证</div>
      <div class="detail-content-main">
        <div class="code-content">
          <div class="code-content-title" v-if="data.status === 3 || data.status === 6">访客通行证</div>
          <div v-if="data.status === 3">
            <img
              v-if="setting.visitorRestrictions && setting.visitorRestrictions.flag === 1"
              :src="pass"
              alt
            >
            <div @click="updateCodeFn">
              <vue-qr
                v-if="qrcodeText !== '' && setting.visitorRestrictions && setting.visitorRestrictions.flag === 2"
                :text="qrcodeText"
                :correct-level="3"
                :size="160"
                :margin="10"
                color-dark="#000"
                color-light="white"
                background-color="white"
                background-dimming="white"
                :logo-scale=".2"
                :logo-margin="5"
                logo-background-color="white"
              />
            </div>
          </div>
          <div class="expired-content" v-if="data.status === 6">
            <img class="expired-content-img" src="../../assets/images/invite-expired.png" alt="">
            <div class="expired-content-text">通行证已过期</div>
          </div>
          <div class="refresh-content" v-if="data.status === 3 && qrcodeText !== '' && setting.visitorRestrictions && setting.visitorRestrictions.flag === 2">
            <div>二维码有效期为 <span style="color:#666;">{{ num }}s</span></div>
            <div>(可以直接点击二维码进行刷新)</div>
          </div>
          <div class="code-img-content" v-if="data.status === 1 || data.status === 2">
            <img class="code-img-content-img" src="../../assets/images/invite-approval-pending.png" alt="">
            <div class="code-img-content-text">访问审批中</div>
          </div>
          <div class="code-img-content" v-if="data.status === 4 || data.status === 5 || data.status === 7 || data.status === 8">
            <img class="code-img-content-img" src="../../assets/images/invite-not-pass.png" alt="">
            <div class="code-img-content-text">审批未通过</div>
          </div>
        </div>
        <div class="main-content">
          <div class="main-items">
            <div class="items-name">姓名</div>
            <div class="items-text">{{ data.visitName }}</div>
          </div>
          <div class="main-items">
            <div class="items-name">电话</div>
            <div class="items-text">{{ data.phone }}</div>
          </div>
          <div class="main-items">
            <div class="items-name">身份证号</div>
            <div class="items-text">{{ data.card }}</div>
          </div>
          <div class="main-items">
            <div class="items-name">随行人数</div>
            <div class="items-text">
              <span v-if="data.num === 0">1至5人</span>
              <span v-if="data.num === 1">大于5人</span>
            </div>
          </div>
          <div class="main-items">
            <div class="items-name">拜访单位</div>
            <div class="items-text">{{ data.organizationName }}</div>
          </div>
          <div class="main-items">
            <div class="items-name">拜访部门</div>
            <div class="items-text">{{ data.departmentName }}</div>
          </div>
          <div class="main-items">
            <div class="items-name">拜访人</div>
            <div class="items-text">{{ data.callName }}</div>
          </div>
          <div class="main-items">
            <div class="items-name">拜访时间</div>
            <div class="items-text">{{ data.start }}</div>
          </div>
          <div class="main-items">
            <div class="items-name">离开时间</div>
            <div class="items-text">{{ data.end }}</div>
          </div>
        </div>
        <div class="main-tips">
          <img class="main-tips-img" src="../../assets/images/invite-note.png" alt="">
          <span class="main-tips-text">如需延长有效期请联系邀请人修改来访时间</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getDepartmentListAPI,
  submitVisitAPI,
  queryVisitAPI,
  getUserSystemSetupAPI,
  getUserInfoAPI
} from '@/api/invite'
import { Toast } from 'vant'
import vueQr from 'vue-qr'
import CommonBorder from '@/components/commonBorder/index.vue'
import pass from '@/assets/pass.png'

export default {
  components: {
    vueQr,
    CommonBorder
  },
  data() {
    return {
      pass,
      visit: {
        departmentId: '',
        verify1Name: '',
        visitName: '',
        gender: 1,
        unit: '',
        reason: '',
        card: '',
        num: '',
        start: '',
        end: '',
        contact: '',
        callName: ''
      },
      start: '',
      end: '',
      reasonText: '',
      numText: '',
      departmentText: '',
      // 被访部门
      departmentList: [],
      // 事由
      reasonList: [
        {
          lable: '开会',
          value: 0
        },
        {
          lable: '面试',
          value: 1
        },
        {
          lable: '拜访',
          value: 2
        },
        {
          lable: '合作',
          value: 3
        },
        {
          lable: '洽谈',
          value: 4
        },
        {
          lable: '访友',
          value: 5
        },
        {
          lable: '推销',
          value: 6
        },
        {
          lable: '其他',
          value: 7
        }
      ],
      // 人数
      numList: [
        {
          lable: '1至5人',
          value: 0
        },
        {
          lable: '大于5人',
          value: 1
        }
      ],
      isDepartmentList: false,
      isReasonList: false,
      isNumList: false,
      isStart: false,
      isEnd: false,
      minDate: new Date(),
      maxDate: new Date(),
      data: {},
      setting: {},
      qrcodeText: '',
      timer: null,
      num: 30,
      succsess: 0
    }
  },
  created() {
    this.getDepartmentListFn()
    var date = new Date()
    this.maxDate = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate() + 7
    )
    this.queryVisitFn()
    this.Hebdomad()
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    async queryVisitFn() {
      const href = window.location.href
      const url = href.split('=')[1]
      const res = await queryVisitAPI(url)
      if (res.code === 0) {
        this.data = res.data
        if (this.data.status === 3) {
          this.getUserSystemSetupFn(this.data.organizationId)
        }
      }
    },
    reasonConfirm(val) {
      if (val) {
        this.reasonText = val.lable
        this.visit.reason = val.value
        this.isReasonList = false
      } else {
        this.isReasonList = false
      }
    },
    numConfirm(val) {
      if (val) {
        this.numText = val.lable
        this.visit.num = val.value
        this.isNumList = false
      } else {
        this.isNumList = false
      }
    },
    departmentConfirm(val) {
      this.departmentText = val.name
      this.visit.departmentId = val.id
      this.isDepartmentList = false
    },
    async getDepartmentListFn() {
      const href = window.location.href
      const url = href.split('=')[1]
      const res = await getDepartmentListAPI(url, '')
      if (res.code === 0) {
        this.departmentList = res.data
        if (this.departmentList.length === 0) {
          this.departmentList.push({ name: '无部门', id: null })
        }
      }
    },
    formatter(type, val) {
      if (type === 'month') {
        return `${val}月`
      } else if (type === 'day') {
        return `${val}日`
      } else if (type === 'year') {
        return `${val}年`
      } else if (type === 'hour') {
        return `${val}时`
      } else if (type === 'minute') {
        return `${val}分`
      }
      return val
    },
    startConfirmFn(val) {
      if (val) {
        this.start = this.timestampToTime(val)
        this.visit.start = this.timestampToTime(val)
        this.isStart = false
      } else {
        this.isStart = false
      }
    },
    endConfirmFn(val) {
      if (val) {
        this.end = this.timestampToTime(val)
        this.visit.end = this.timestampToTime(val)
        this.isEnd = false
      } else {
        this.isEnd = false
      }
    },
    timestampToTime(time) {
      var date = time
      var y = date.getFullYear()
      var M = date.getMonth() + 1
      if (M < 10) {
        M = '0' + M
      }
      var d = date.getDate()
      if (d < 10) {
        d = '0' + d
      }
      var h = date.getHours()
      if (h < 10) {
        h = '0' + h
      }
      var m = date.getMinutes()
      if (m < 10) {
        m = '0' + m
      }
      return `${y}-${M}-${d} ${h}:${m}:00`
    },
    async submitVisitFn() {
      if (this.rulesFn()) {
        const res = await submitVisitAPI({ visit: this.visit }, this.data.id)
        if (res.code === 0) {
          Toast({
            message: '提交成功!'
          })
          window.location.href = window.location.href
        }
      }
    },
    rulesFn() {
      const {
        departmentId,
        visitName,
        unit,
        reason,
        card,
        num,
        start,
        contact,
        callName,
        end
      } = this.visit
      if (visitName.trim() === '') {
        Toast({
          message: '请输入来访人!'
        })
        return false
      }
      if (contact.trim() === '' || contact.trim().length !== 11) {
        Toast({
          message: '手机号不能为空或手机号不为11位!'
        })
        return false
      }
      if (unit.trim() === '') {
        Toast({
          message: '请输入来访单位!'
        })
        return false
      }
      if (reason === '') {
        Toast({
          message: '请选择来访事由!'
        })
        return false
      }
      if (card.trim() === '' || card.trim().length !== 18) {
        Toast({
          message: '身份证不能为空或身份证不为18位!'
        })
        return false
      }
      if (num === '') {
        Toast({
          message: '请选择随行人数!'
        })
        return false
      }
      if (departmentId === '') {
        Toast({
          message: '请选择被访部门!'
        })
        return false
      }
      if (callName.trim() === '') {
        Toast({
          message: '请输入被访人!'
        })
        return false
      }
      if (start === '' || start > this.Hebdomad()) {
        Toast({
          message: '来访时间未选择或选择时间大于7天!'
        })
        return false
      }
      if (this.visit.end !== '' && this.visit.end > this.Hebdomad()) {
        Toast({
          message: '离开时间选择范围不能大于7天!'
        })
        return false
      }
      if (start < this.getNowDate()) {
        Toast({
          message: '来访时间不能小于当前时间!'
        })
        return false
      }
      if (end && end < start) {
        Toast({
          message: '来访时间不能小于离开时间!'
        })
        return false
      }
      return true
    },
    Hebdomad() {
      var date = new Date()
      var oneweekdate = new Date(date.getTime() + 7 * 24 * 60 * 60 * 1000)
      var y = oneweekdate.getFullYear() + '-'
      var m =
        (oneweekdate.getMonth() + 1 < 10
          ? '0' + (oneweekdate.getMonth() + 1)
          : oneweekdate.getMonth() + 1) + '-'
      var d =
        oneweekdate.getDate() < 10
          ? '0' + oneweekdate.getDate()
          : oneweekdate.getDate()
      var s =
        (oneweekdate.getHours() < 10
          ? '0' + oneweekdate.getHours()
          : oneweekdate.getHours()) + ':' // 获得小时
      var f =
        (oneweekdate.getMinutes() < 10
          ? '0' + oneweekdate.getMinutes()
          : oneweekdate.getMinutes()) + ':' // 获得分钟
      var miao = oneweekdate.getSeconds() // 获得秒数
      return y + m + d + ' ' + s + f + miao
    },
    async getUserSystemSetupFn(id) {
      const res = await getUserSystemSetupAPI(id)
      if (res.code === 0 && res.data) {
        if (res.data.setting) {
          this.setting = JSON.parse(res.data.setting)
          if (
            this.setting.visitorRestrictions &&
            this.setting.visitorRestrictions.flag === 2
          ) {
            this.getUserInfoFn()
          }
        }
      }
    },
    numFn() {
      this.timer = setInterval(() => {
        this.num--
        if (this.num === 0) {
          clearInterval(this.timer)
          this.getUserInfoFn()
        }
      }, 1000)
    },
    updateCodeFn() {
      clearInterval(this.timer)
      this.getUserInfoFn()
    },
    async getUserInfoFn() {
      if (this.succsess === 0) {
        this.succsess = 1
        const res = await getUserInfoAPI({
          organizationId: this.data.organizationId,
          name: this.data.visitName,
          userId: this.data.userId,
          startTime: this.data.start,
          endTime: this.data.end,
          visitorId: this.data.id
        })
        if (res.code === 0) {
          this.qrcodeText = res.data
          this.numFn()
          this.num = 30
          this.succsess = 0
        }
      }
    },
    getNowDate() {
      var date = new Date()
      // 年 getFullYear()：四位数字返回年份
      var year = date.getFullYear() // getFullYear()代替getYear()
      // 月 getMonth()：0 ~ 11
      var month = date.getMonth() + 1
      // 日 getDate()：(1 ~ 31)
      var day = date.getDate()
      // 时 getHours()：(0 ~ 23)
      var hour = date.getHours()
      // 分 getMinutes()： (0 ~ 59)
      var minute = date.getMinutes()
      // 秒 getSeconds()：(0 ~ 59)
      var second = date.getSeconds()

      var time =
        year +
        '-' +
        this.addZero(month) +
        '-' +
        this.addZero(day) +
        ' ' +
        this.addZero(hour) +
        ':' +
        this.addZero(minute) +
        ':' +
        this.addZero(second)
      return time
    },

    // 小于10的拼接上0字符串
    addZero(s) {
      return s < 10 ? '0' + s : s
    }
  }
}
</script>

<style lang='scss' scoped>
.container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .invite-content{
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    .invite-title-content{
      height: 44px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #000;
      font-weight: 500;
      background: #fff;
      margin-bottom: 10px;
    }
    .invite-main-content{
      background: #fff;
      .invite-main-items{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: #fff;
        box-sizing: border-box;
        padding-left: 16px;
        height: 45px;
        .items-title{
          color: #333;
          font-size: 16px;
          .items-red-star{
            color:#F00703;
            margin-right: 5px;
          }
        }
      }
    }
    .mb-15{
      margin-bottom: 15px;
    }
    .invite-submit-content{
      width: 100%;
      box-sizing: border-box;
      padding: 30px 16px 0;
    }
    .invite-submit-btn{
      width: 100%;
      background: #FA5050;
      color: #fff;
      border-color: #FA5050;
      font-size: 18px;
    }
  }
  .invite-detail-content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .detail-content-title{
      color: #000;
      font-weight: 500;
      width: 100%;
      background: #fff;
      height: 44px;display: flex;
      flex-direction: row;
      align-items: center;
      box-sizing: border-box;
      padding-left: 16px;
      min-height: 44px;
      max-height: 44px;
    }
    .detail-content-main{
      flex:1;
      box-sizing: border-box;
      padding:10px 16px;
      overflow: auto;
      .code-content{
        background: #fff;
        margin-bottom: 15px;
        box-sizing: border-box;
        padding:20px 16px 25px;
        border-radius:10px;
        display: flex;
        flex-direction:column;
        align-items: center;
        .code-content-title{
          font-size: 18px;
          color:#333;
          font-weight: 500;
          margin-bottom: 16px;
        }
        .expired-content{
          position: relative;
          width: 200px;
          height:160px;
          text-align: center;
          .expired-content-img{
            width: 160px;
            height:160px;
          }
          .expired-content-text{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            color: #fff;
            font-size: 16px;
          }
        }
        .refresh-content{
          font-size: 14px;
          color:#999;
          text-align: center;
        }
        .code-img-content{
          display:flex;
          flex-direction:column;
          align-items:center;
          justify-content: center;
          height:200px;
          .code-img-content-img{
            width: 90px;
            height:90px;
            margin-bottom: 35px;
          }
          .code-img-content-text{
            color: #333;
          }
        }
      }
      .main-content{
        background: #fff;
        margin-bottom: 15px;
        box-sizing: border-box;
        padding:0px 16px;
        border-radius:10px;
        .main-items{
          height: 44px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          font-size: 16px;
          .items-name{
            color: #333;
          }
          .items-text{
            color: #666;
          }
        }
      }
      .main-tips{
        height: 28px;
        display: flex;
        flex-direction:row;
        align-items:center;
        justify-content: center;
        .main-tips-img{
          width:24px;
          height:24px;
          margin-right: 4px;
        }
        .main-tips-text{
          color:#666;
          font-size: 14px;
        }
      }
    }
  }
}


::v-deep .van-field {
  border: none !important;
}
::v-deep .van-dialog__confirm {
  background-color: white !important;
}
::v-deep .van-picker__toolbar{
  background: #f1f1f1;
  height: 50px;
}
::v-deep .van-picker__cancel{
  font-size: 16px;
  color: #666;
}
::v-deep .van-picker__confirm{
  font-size: 16px;
  color: #FA5050;
}
::v-deep .van-ellipsis{
  color: #333;
} 
</style>
